.dashboard__wrapper {
    max-height: calc(100vh - 170px - 48px);
    overflow-y: scroll;
}

.dashboard__text-center {
    text-align: center;
}

.dashboard__pie-wrapper {
    position: relative;
}

.dashboard__pie-count {
    font-size: 12px;
    position: absolute;
    bottom: -32px;
    right: 24px;
}