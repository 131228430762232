.rowWrapper > td {
    padding: 0 !important;
}

.rowWrapper td .ant-table,
.rowWrapper td .ant-table-small {
    margin-block: 0 !important;
    margin-inline: 0 !important;
}

.alert__content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.alert__list {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.alert_table {
    margin-top: 16px;
    flex-grow: 1;
}

.alerts-table_header {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal !important;
}

.alert-card__spinner,
.alert-card__spinner > .ant-spin-container,
.alert-card__spinner > .ant-spin-container > .ant-form {
    height: 100%;
}
