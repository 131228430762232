.transaction__link {
    cursor: pointer;
    color: #699ec0;
}

.transactions-details {
    height: 70vh;
}

.transaction-details__wrapper {
    overflow-y: auto;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
}

.transactions-details__main {
    height: calc(75% - 24px);
    margin-bottom: 24px;
}

.transactions-details__item {
    display: flex;
    border-bottom: 1px solid #f1f1f1;
    padding: 2px 8px;
}

.transactions-details__rules {
    height: 25%;
}


