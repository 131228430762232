.calendar__wrapper {
    width: 300px;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 8px;
    margin-bottom: 16px;
}

.calendar__description {
    width: 300px;
    padding: 0 16px;
    text-align: center;
    color: #7c7c7c;
}

.calendar__wrapper .ant-picker-calendar-mode-switch {
    display: none !important;
}

.reports-row {
    border-bottom: 1px solid rgb(240, 240, 240);
    padding-top: 4px;
    padding-bottom: 4px;
}

.reports-tooltip {
    max-width: 200px;
}
