.login__form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login__form .ant-card {
    width: 400px;
}

.login__form .ant-card-head {
    background-color: #445271;
}

.login__form .ant-card-head-title {
    padding: 10px 0;
}